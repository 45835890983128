<template>
  <div
    class="
      flex flex-wrap
      sm:flex-row
      justify-center
      items-center
      space-x-5
      mb-5
      text-gray-400 text-xs
    "
    id="app"
  >
    <router-link to="/"><p>Colibyte UG (Haftungsbeschränkt)</p></router-link>
    <router-link to="/impressum"><p>Impressum</p></router-link>
    <a
      href="https://www.iubenda.com/privacy-policy/39233390/full-legal"
      title="Datenschutzerklärung "
      >Datenschutzerklärung</a
    >
    <a
      href="https://www.iubenda.com/privacy-policy/39233390/cookie-policy"
      title="Cookies"
      >Cookies</a
    >
  </div>
</template>