<template>
  <div class="w-full h-screen relative">
    <img class="absolute object-cover h-screen w-full" src="@/assets/bg.jpg" />
    <div class="absolute bg-black opacity-80 z-10 w-full h-screen"></div>
    <div
      class="
        absolute
        z-20
        w-full
        h-screen
        flex flex-col
        justify-between
        items-center
      "
    >
      <div></div>
      <div
        class="
          flex flex-col
          justify-center
          items-center
          text-xl
          sm:text-2xl
          text-white
        "
      >
        <img src="@/assets/bird.png" class="w-20 sm:w-28 aspect-auto mb-5" />
        <p
          class="
            text-5xl
            sm:text-9xl
            font-extrabold
            text-transparent
            bg-clip-text bg-gradient-to-br
            from-DentaBlue
            to-DentaPink
          "
        >
          Wir bauen um!
        </p>
        <p class="mt-6">Wir kommen mit neuem Look wieder.</p>
        <div class="flex justify-center items-center space-x-10 mt-20">
          <a
            href="https://www.instagram.com/colibyte/"
            target="_blank"
            class="transition duration-100 hover:scale-110"
          >
            <img src="@/assets/insta.svg" class="w-12 h-12" />
          </a>
          <a
            href="https://www.linkedin.com/company/colibyte-ug-haftungsbeschr%C3%A4nkt/"
            target="_blank"
            class="transition duration-100 hover:scale-110"
          >
            <img src="@/assets/linkedin.svg" class="w-12 h-12" />
          </a>
          <a
            href="mailto:info@colibyte.de"
            class="transition duration-100 hover:scale-110"
          >
            <img src="@/assets/mail.svg" class="w-12 h-12" />
          </a>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
export default {
  components: { Footer },
};
</script>