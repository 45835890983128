<template>
  <div class="w-full h-screen relative">
    <img src="@/assets/bg.jpg" class="absolute w-full h-screen object-cover" />
    <div
      class="absolute flex flex-col bg-black opacity-80 z-10 w-full h-screen"
    ></div>
    <div
      class="
        absolute
        z-20
        w-full
        h-screen
        flex flex-col
        justify-between
        items-center
      "
    >
      <div
        class="
          flex flex-col
          justify-start
          items-start
          overflow-y-auto
          px-10
          pt-10
          text-white
        "
      >
        <p class="3DMsoNoSpacing">&nbsp;</p>
        <p><strong>Impressum</strong></p>
        <p>&nbsp;</p>
        <p>
          <span style="text-decoration: underline">Angaben gem. § 5 TMG:</span>
        </p>
        <p>&nbsp;</p>
        <p>Scott, Clements</p>
        <p>Hochstraße 47</p>
        <p>64285</p>
        <p>&nbsp;</p>
        <p><span style="text-decoration: underline">Kontaktaufnahme:</span></p>
        <p>&nbsp;</p>
        <p>Telefon: 06151 4296756</p>
        <p>E-Mail: info@colibyte.de</p>
        <p>&nbsp;</p>
        <p><strong>Umsatzsteuer-ID</strong></p>
        <p><span style="text-decoration: underline">&nbsp;</span></p>
        <p>
          <span style="text-decoration: underline"
            >Umsatzsteuer-Identifikationsnummer gem. § 27 a
            Umsatzsteuergesetz:</span
          >
        </p>
        <p>&nbsp;</p>
        <p>DE339864725</p>
        <p>
          <strong
            ><span style="text-decoration: underline">&nbsp;</span></strong
          >
        </p>
        <p><strong>Haftungsausschluss - Disclaimer:</strong></p>
        <p>&nbsp;</p>
        <p>
          <span style="text-decoration: underline">Haftung für Inhalte</span>
        </p>
        <p><strong>&nbsp;</strong></p>
        <p>
          Alle Inhalte unseres Internetauftritts wurden mit größter Sorgfalt und
          nach bestem Gewissen erstellt. Für die Richtigkeit, Vollständigkeit
          und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
          auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
          §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
          Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
          Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
          hiervon unberührt.
        </p>
        <p>
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntniserlangung einer konkreten Rechtsverletzung möglich. Bei
          Bekanntwerden von den o.g. Rechtsverletzungen werden wir diese Inhalte
          unverzüglich entfernen.
        </p>
        <p>&nbsp;</p>
        <p>
          <span style="text-decoration: underline"
            >Haftungsbeschränkung für externe Links</span
          >
        </p>
        <p><strong>&nbsp;</strong></p>
        <p>
          Unsere Webseite enthält Links auf externe Webseiten Dritter. Auf die
          Inhalte dieser direkt oder indirekt verlinkten Webseiten haben wir
          keinen Einfluss. Daher können wir für die „externen Links“ auch keine
          Gewähr auf Richtigkeit der Inhalte übernehmen. Für die Inhalte der
          externen Links sind die jeweilige Anbieter oder Betreiber (Urheber)
          der Seiten verantwortlich.
        </p>
        <p>
          Die externen Links wurden zum Zeitpunkt der Linksetzung auf eventuelle
          Rechtsverstöße überprüft und waren im Zeitpunkt der Linksetzung frei
          von rechtswidrigen Inhalten. Eine ständige inhaltliche Überprüfung der
          externen Links ist ohne konkrete Anhaltspunkte einer Rechtsverletzung
          nicht möglich. Bei direkten oder indirekten Verlinkungen auf die
          Webseiten Dritter, die außerhalb unseres Verantwortungsbereichs
          liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall
          nur bestehen, wenn wir von den Inhalten Kenntnis erlangen und es uns
          technisch möglich und zumutbar wäre, die Nutzung im Falle
          rechtswidriger Inhalte zu verhindern.
        </p>
        <p>
          Diese Haftungsausschlusserklärung gilt auch innerhalb des eigenen
          Internetauftrittes „<em>www.colibyte.de</em>“ gesetzten Links und
          Verweise von Fragestellern, Blogeinträgern, Gästen des
          Diskussionsforums. Für illegale, fehlerhafte oder unvollständige
          Inhalte und insbesondere für Schäden, die aus der Nutzung oder
          Nichtnutzung solcherart dargestellten Informationen entstehen, haftet
          allein der Diensteanbieter der Seite, auf welche verwiesen wurde,
          nicht derjenige, der über Links auf die jeweilige Veröffentlichung
          lediglich verweist.
        </p>
        <p>
          Werden uns Rechtsverletzungen bekannt, werden die externen Links durch
          uns unverzüglich entfernt.
        </p>
        <p>&nbsp;</p>
        <p><span style="text-decoration: underline">Urheberrecht</span></p>
        <p><strong>&nbsp;</strong></p>
        <p>
          Die auf unserer Webseite veröffentlichen Inhalte und Werke unterliegen
          dem deutschen Urheberrecht (<a
            href="http://www.gesetze-im-internet.de/bundesrecht/urhg/gesamt.pdf"
            >http://www.gesetze-im-internet.de/bundesrecht/urhg/gesamt.pdf</a
          >) . Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
          Verwertung des geistigen Eigentums in ideeller und materieller Sicht
          des Urhebers außerhalb der Grenzen des Urheberrechtes bedürfen der
          vorherigen schriftlichen Zustimmung des jeweiligen Urhebers i.S.d.
          Urhebergesetzes (<a
            href="http://www.gesetze-im-internet.de/bundesrecht/urhg/gesamt.pdf"
            >http://www.gesetze-im-internet.de/bundesrecht/urhg/gesamt.pdf</a
          >
          ). Downloads und Kopien dieser Seite sind nur für den privaten und
          nicht kommerziellen Gebrauch erlaubt. Sind die Inhalte auf unserer
          Webseite nicht von uns erstellt wurden, sind die Urheberrechte Dritter
          zu beachten. Die Inhalte Dritter werden als solche kenntlich gemacht.
          Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
          werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
          von Rechtsverletzungen werden wir derartige Inhalte unverzüglich
          entfernen.
        </p>
        <p>
          Dieses
          <a href="http://www.jurarat.de/muster-impressum">Impressum</a> wurde
          freundlicherweise von www.jurarat.de zur Verfügung gestellt.
        </p>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
export default {
  components: { Footer },
};
</script>