import { createRouter, createWebHistory } from 'vue-router'
import Umbau from '../views/Umbau.vue'
import Impressum from '../views/Impressum.vue'
import NotFound from '../views/NotFound.vue'


const routes = [
  {
    path: '/',
    redirect: '/bisbald'
  },
  {
    path: '/bisbald',
    name: 'umbau',
    component: Umbau
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: Impressum
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: NotFound
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
