<template>
  <div class="w-full h-screen relative">
    <img class="absolute object-cover h-screen w-full" src="@/assets/bg.jpg" />
    <div class="absolute bg-black opacity-80 z-10 w-full h-screen"></div>
    <div
      class="
        absolute
        z-20
        w-full
        h-screen
        flex flex-col
        justify-between
        items-center
      "
    >
      <div></div>
      <div
        class="
          flex flex-col
          justify-center
          items-center
          text-xl
          sm:text-2xl
          text-white
        "
      >
        <p
          class="
            text-5xl
            sm:text-9xl
            font-extrabold
            text-transparent
            bg-clip-text bg-gradient-to-br
            from-DentaBlue
            to-DentaPink
          "
        >
          401
        </p>
        <p class="mt-5">Hier haben wir dich garnicht erwartet!</p>
        <router-link to="/">
          <div
            class="
              px-6
              py-3
              bg-white
              rounded-md
              text-black text-xl
              mt-12
              transform
              duration-150
              hover:cursor-pointer hover:scale-105
            "
          >
            Zurück
          </div>
        </router-link>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
export default {
  components: { Footer },
};
</script>
